<template>
  <div id="app">
    <header>
      <h1>欢迎来到我的个人介绍页面</h1>
    </header>
    <main>
      <section>
        <h2>关于我</h2>
        <img src="./assets/avatar.png" alt="个人头像" style="width:150px; border-radius:50%;">
        <p>您好！我是廖俊杰，一名软件工程师，专注测试开发和云计算技术。</p>
      </section>
      <section>
        <h2>我的技能</h2>
        <ul>
          <li>Java / Spring</li>
          <li>JavaScript / TypeScript</li>
          <li>Vue.js / React</li>
          <li>Node.js / Express</li>
          <li>Docker / Kubernetes</li>
          <li>AWS / Azure</li>
        </ul>
      </section>
      <section>
        <h2>联系我</h2>
        <p>邮箱: <a href="mailto:liaojunjie@example.com">liaojunjie@example.com</a></p>
               <p>GitHub: <a href="https://github.com/yourusername" target="_blank">github.com/yourusername</a></p>
      </section>
    </main>
    <footer>
      <p>&copy; <a href="https://beian.miit.gov.cn/">粤ICP备2024315814号</a></p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}
header {
  background-color: #42b983;
  color: white;
  padding: 20px;
  text-align: center;
}
main {
  padding: 20px;
}
section {
  margin-bottom: 20px;
}
footer {
  background-color: #42b983;
  color: white;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 0;
}
img {
  display: block;
  margin: 10px auto;
}
</style>



